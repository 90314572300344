<!-- 新增用户 -->
<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="200px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="11">
          <el-form-item label="用户名" prop="name">
            <el-input
              type="text"
              v-model="ruleForm.name"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="手机号" prop="tel">
            <el-input
              type="text"
              v-model="ruleForm.tel"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="11">
          <el-form-item label="密码" prop="pwd">
            <el-input
              type="text"
              v-model="ruleForm.pwd"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="等级" prop="level">
            <el-select
              style="width: 100%"
              v-model="ruleForm.level"
              placeholder="请选择等级"
            >
              <el-option label="项目主管" :value="1"> 项目主管 </el-option>
              <el-option label="小组主管" :value="2"> 小组主管 </el-option>
              <el-option label="线上销售人员" :value="3">
                线上销售人员
              </el-option>
              <el-option label="线下办单人员" :value="4">
                线下办单人员
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="ruleForm.level == 3 || ruleForm.level == 4">
        <el-col :span="11">
          <el-form-item label="小组" prop="group_id">
            <el-select
              style="width: 100%"
              v-model="ruleForm.group_id"
              placeholder="请选择小组"
               @focus="focus"
            >
              <el-option
                :label="item.group_name"
                :value="item.group_id"
                v-for="(item, index) in group"
                :key="index"
              >
                {{ item.group_name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保存</el-button
          >
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        tel: "",
        pwd: "",
        level: "",
        group_id: "",
      },
      group: [],
      user: JSON.parse(window.localStorage.getItem("user")),
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        tel: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        level: [{ required: true, message: "请选择等级", trigger: "blur" }],
        group_id: [{ required: true, message: "请选择小组", trigger: "blur" }],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let response = await this.$request.post("/addUser", this.ruleForm);
          if (response.data.success) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.resetForm();
          } else {
            this.$message({
              message: response.data.msg,
              type: "error",
            });
          }
        } else {
          this.$message({
            message: "请输入必选项",
            type: "error",
          });
          return false;
        }
      });
    },

    async focus() {
      let data = {
        level: this.user.level,
        user_id: this.user.id,
      };
      let response = await this.$request.post("/showGroups", data);
      if (response.data.success) {
        this.group = response.data.data;
      } else {
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    // 重置
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>
<style lang='less' scoped>
</style>